<template>
  <div>
    {{user.name}}
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      user: {}
    }
  },
  mounted(){
    this.user = JSON.parse(sessionStorage.getItem("user"));
  },
}
</script>

<style scoped>

</style>